@import "../../../../Styles/master";

#home {
  background-color: $page-color;

  .home-header {
    @extend %textbox;

    @include responsive(tablet) {
      // margin-top: 2rem;
      max-width: 75%;
      font-size: $text-section-header;
    }
  }

  #home-image {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 60vh;
    max-height: 80vw;
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;

    @include responsive(tablet) {
      max-height: 100vw;
    }
  }

  #home-fuelContainer {
    padding: 8rem 0 6rem 0;
    color: $primary;
    font-family: $text-font;
    text-align: center;
    @extend %background-gradient-dark;

    #home-fuelList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .home-fuel {
        flex-basis: 25%;
        box-sizing: border-box;
        font-size: $text-large;

        @include responsive(tablet) {
          flex-basis: 50%;
          padding-bottom: 4rem;
        }

        @include responsive(mobile) {
          flex-basis: 100%;
        }

        .home-fuelName {
          font-size: $text-section-header;
          font-weight: bold;
        }
      }
    }

    #home-fuelInfo {
      padding-top: 2rem;
      font-size: $text-large;
    }

    #home-fuelInfo,
    #home-fuelDate {
      margin: auto;

      @include responsive(tablet) {
        width: 80%;
      }

      @include responsive(tabletSmall) {
        width: 90%;
      }

      @include responsive(mobile) {
        width: 95% !important;
      }
    }

    #home-fuelDate {
      padding-top: 2rem;
      font-size: $text-standard;
    }
  }

  #home-serviceListContainer {
    margin: 8rem 0rem;

    #home-serviceListHeading {
      margin: auto;
      margin: 4rem 0rem;
      padding: 0rem 2rem;
      color: $primary-contrast;
      font-family: $heading-font;
      font-size: $text-section-header;
      font-weight: bold;
      text-align: center;
    }

    #home-serviceList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 0.5rem;
      padding-left: 16.5%;

      @include responsive(tablet) {
        justify-content: center;
        width: 80%;
        margin: auto;
        padding-left: unset;
      }

      @include responsive(tabletSmall) {
        width: 90%;
      }

      @include responsive(mobile) {
        width: 95% !important;
      }

      @include responsive(widescreen) {
        padding-left: 25%;
      }

      .home-service {
        display: flex;
        flex-basis: 50%;
        padding: 0.5rem;
        box-sizing: border-box;
        transition: 0.25s linear all;
        color: black;
        font-family: $text-font;
        font-size: $text-large;
        font-weight: bold;
        text-decoration: underline;

        @include responsive(widescreen) {
          flex-basis: 50%;
        }

        @include responsive(tablet) {
          justify-content: center;
          flex-basis: 100%;
          padding: 0.75rem 0.125rem;

          #home-serviceLongItem {
            text-align: center;
          }
        }

        &:hover {
          color: $primary;
        }

        &#home-serviceLong {
          flex-basis: 100% !important;
        }

        .home-serviceIcon {
          font-size: $text-standard;
          line-height: $text-standard;
          vertical-align: bottom;
        }
      }
    }
  }
}
