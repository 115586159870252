@import "../../../../Styles/master";

#about-textContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  #about-title {
    font-family: $heading-font;
    font-size: $text-page-header;
    font-weight: bold;
    color: $primary;
    text-align: center;
    flex-basis: 100%;
    padding: 4rem 0.5rem;

    @include responsive(tabletSmall) {
      font-size: $text-section-header;
      padding-left: 0.15rem;
      padding-right: 0.15rem;
    }
  }

  #about-text {
    @extend %paragraph-text;
  }
}
