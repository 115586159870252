@import "../../../Styles/master";

#footer {
  padding-top: 4rem;
  background-color: $background-tertiary;
  color: $background-tertiary-text;
}

#footer-logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  #footer-logo {
    height: 8rem;
    width: auto;
  }
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  font-family: $text-font;
  font-size: $text-link;
  font-weight: bold;
  list-style: none;

  .footer-linkContainer {
    display: block;
    min-width: 12rem;
    padding: 1rem 2rem;
    text-align: center;

    @include responsive(mobile) {
      flex-basis: 100%;
    }

    .footer-link {
      display: block;
      width: 100%;
      transition: all 0.3s ease-in-out;
      color: $background-tertiary-text;
      text-decoration: none;

      &:hover {
        color: $primary;
      }
    }
  }
}

#footer-info {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.25rem;
  font-family: $text-font;
  font-size: $text-small;

  #footer-copyright {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-grow: 1;
    padding-bottom: 0.25rem;
  }

  #footer-facebookLink {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    padding: 0.5rem;

    @include responsive(mobile) {
      margin-bottom: 1rem;
    }

    #footer-facebookLogo {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      background-color: $background-tertiary-text;

      &:hover {
        background-color: $primary;
        cursor: pointer;
      }
    }
  }
}
