@import "../../../../Styles//master";

#testimonials {
  #testimonials-image {
    @include parallax;

    #testimonials-header {
      @extend %textbox;
    }
  }

  #testimonials-content {
    padding: 4rem 1rem;
    font-family: $text-font;

    #testimonials-title {
      text-align: center;
      font-size: $text-section-header;
      font-weight: bold;
      padding-bottom: 4rem;
    }

    .testimonials-testimonial {
      width: 60%;
      margin: auto;
      font-size: $text-standard;

      @include responsive(tabletSmall) {
        width: 80%;
      }

      @include responsive(mobile) {
        width: 95%;
      }

      .testimonials-quote {
        text-align: center;
        font-style: italic;
      }

      .testimonials-info {
        padding-top: 1rem;
        text-align: right;
      }
    }
  }
}
