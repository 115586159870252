@import "../../../Styles/master";

.bam {
  margin-left: auto;

  .bam-logo {
    width: auto;
    height: 2rem;
  }
}
