@import "../../../Styles/master";

$topNav-height: 3rem;
$stickyNav-height: 2rem;
$link-height: 1.5rem;
$mobileLink-height: 1.25rem;
$sublistLink-height: 2.5rem;

.navBar-linkSlidedown,
.navBar-servicesSlidedown {
  transition-duration: 0.3s;
}

#navBar-infoBar {
  display: flex;
  align-items: center;
  // min-height: 1.5rem;
  padding: 0.25rem 0.25rem;
  background-color: $background-secondary;
  color: $background-secondary-text;
  font-family: $text-font;
  font-size: $text-small;
  font-weight: bold;

  @include responsive(mobile) {
    #navBar-address {
      padding-top: 0 !important;
    }
  }

  @include responsive(tabletSmall) {
    #navBar-addressText {
      display: none;
    }
  }

  @include responsive(tablet) {
    justify-content: center;
    flex-wrap: wrap;
    font-size: $text-tiny;
  }

  #navBar-facebookLink,
  #navBar-facebookLogo {
    height: 1rem;
    width: 1rem;
  }

  #navBar-facebookLink,
  #navBar-phoneNumber {
    padding: 0.1rem;
  }

  #navBar-facebookLink {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  #navBar-address,
  #navBar-phoneNumber {
    display: flex;
    align-items: center;
  }

  #navBar-phoneNumber {
    text-decoration: none;
    color: inherit;
    margin-right: 0.5rem;
  }

  #navBar-address {
    margin-left: auto;
    padding: 0.1rem;
    text-align: center;

    .navBar-locationLink {
      display: flex;
      align-items: center;
      color: $background-secondary-text;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .navBar-infoIcon {
    margin-right: 0.25rem;
    color: $background-secondary-text;
    font-size: 1rem;
  }
}

#navBar-navigation,
#navBar-stickyNavigation {
  @extend %background-gradient-dark;

  .navBar-mobileNavMenu {
    display: none;
    position: absolute;
    right: 0.075em;
    color: $background-text;
  }

  .navBar-linkSlidedown {
    overflow: hidden;
    padding-bottom: 0.25rem;

    .navBar-links {
      margin: 0;
      padding: 0;

      .navBar-linkContainer {
        min-height: $link-height;
        list-style: none;

        .navBar-link {
          display: inline-flex;
          height: 1.25rem;
          color: $background-text;
          font-family: $text-font;
          text-decoration: none;

          &:hover {
            color: $primary;
          }

          .navBar-linkText {
            display: inline-flex;
            align-items: center;
            // height: 75%;
            font-weight: bold;
            text-decoration: none !important;
          }

          &.navBar-link-active {
            color: $primary;
          }

          .navbar-sublistLink-active {
            .navBar-linkText {
              color: $primary !important;
            }
          }
        }

        .navBar-sublistLink {
          font-size: $text-link !important;

          &:hover {
            .navBar-linkText {
              color: $primary !important;
            }
          }
        }

        .navBar-expandServicesIconContainer {
          display: flex;
          align-items: center;
          margin-right: -2rem;
          .navBar-expandServicesIcon {
            font-size: 2rem;
          }
        }

        .navBar-serviceLinks {
          margin: 0;
          padding: 0.5rem;
          text-align: center;

          @include responsive(desktop-thin) {
            width: 10rem;
            margin-top: 0.1rem;
            margin-left: -2.5rem;
            background-color: $background;
          }

          .navBar-sublistLinkContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: $sublistLink-height;
            list-style: none;

            .navBar-sublistLink {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              color: $background-text;
              font-family: $text-font;
              font-size: 1rem; // $text-link;
              font-weight: bold;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

#navBar-navigation {
  @include responsive(tablet) {
    #navBar-topNavMobileMenu {
      display: flex;
    }

    .navBar-links {
      flex-wrap: wrap;

      .navBar-linkContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: $mobileLink-height !important;
        width: 100%;
        padding: 0.75rem;
        border-top: 1.5px solid $background-text;

        .navBar-link {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 1.25rem;
          width: 100%;
        }

        .navBar-servicesSlidedown {
          width: 100%;
          overflow: hidden;

          .navBar-serviceLinks {
            display: block;
            width: 100%;

            .navBar-sublistLinkContainer {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;

              .navBar-sublistLink-active {
                color: $primary !important;
              }
            }
          }
        }
      }
    }
  }
  #navBar-logoHomeLink {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $topNav-height;
    padding: 1rem 0;
    text-decoration: none;

    #navBar-companyLogo {
      height: 6rem;
      width: auto;
      margin-right: 1rem;

      @include responsive(tabletSmall) {
        height: 4rem;
        margin-right: 0.75rem;
      }
    }

    #navBar-companyName {
      color: $company-name;
      font-family: $heading-font;
      font-size: 3.25rem;
      text-align: center;

      #navBar-trustedSince {
        font-family: $text-font;
        font-size: 1.75rem;
        font-style: italic;
        text-align: center;
      }

      @include responsive(tablet) {
        font-size: 2.75rem;

        #navBar-trustedSince {
          font-size: 1.5rem;
        }
      }

      @include responsive(tabletSmall) {
        font-size: 2.25rem !important;

        #navBar-trustedSince {
          font-size: 1.5rem;
        }
      }

      @include responsive(mobile) {
        padding-right: 2.5rem;
        font-size: 2rem !important;

        #navBar-trustedSince {
          font-size: 1.25rem;
        }
      }
    }

    .navBar-mobileLinksMenu {
      font-size: 3rem;
    }
  }

  .navBar-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include responsive(desktopThin) {
      align-items: center;

      .navBar-linkContainer {
        &:not(:first-child) {
          padding-left: 1rem;
        }

        .navBar-link-active {
          .navBar-linkText {
            border-bottom: 2px solid $primary;
          }
        }

        .navBar-expandServicesIconContainer {
          display: none !important;
        }

        .navBar-serviceLinks {
          background-color: $background !important;
          z-index: 1;

          .navBar-sublistLinkContainer {
            .navBar-sublistLink-active {
              .navBar-linkText {
                color: $primary;
              }
            }
            .navBar-linkText {
              color: $secondary-contrast;
              font-size: $text-linkSmall;
            }

            &:not(:first-child) {
              border-top: 1.5px solid $secondary-contrast !important;
            }
          }
        }

        &:not(:hover) {
          .navBar-serviceLinks {
            display: none;
          }
        }

        &:hover {
          .navBar-serviceLinks {
            position: absolute;
          }
        }
      }
    }

    @include responsive(desktop) {
      .navBar-linkContainer {
        &:not(:first-child) {
          padding-left: 2rem;
        }
      }
    }

    @include responsive(widescreen) {
      .navBar-linkContainer {
        &:not(:first-child) {
          padding-left: 4rem;
        }
      }
    }

    .navBar-link {
      font-size: $text-link;
    }
  }
}

#navBar-stickyNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: -$stickyNav-height - 0.1rem;
  height: $stickyNav-height;
  width: 100%;
  transition: top 0.3s linear;
  z-index: 1;

  @include responsive(mobileSmall) {
    justify-content: flex-start;

    .navBar-mobileLinksMenu {
      font-size: 2rem;
    }

    #navBar-companyNameSticky {
      margin-right: 1rem;
      font-size: $text-standard;
    }
  }

  @include responsive(stickyNav-linkCutoff-dropdown) {
    flex-wrap: wrap;

    #navBar-logoHomeLinkSticky {
      display: flex;
      width: 100%;
    }

    #navBar-stickyNavMobileMenu {
      display: flex;
    }

    .navBar-expandServicesIconContainer {
      display: flex !important;
    }

    .navBar-linkSlidedown {
      width: 100%;

      .navBar-links {
        flex-wrap: wrap;
        width: 100%;
        font-size: $text-link;

        &.navBar-mobileLinksShown {
          height: auto;
        }

        .navBar-linkContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          min-height: $mobileLink-height !important;
          width: 100%;
          margin: 0;
          padding: 0;
          border-top: 1.5px solid $background-text;

          .navBar-link {
            align-items: center;
            justify-content: center;
            width: 100%;
          }

          .navBar-servicesSlidedown {
            width: 100%;
            overflow: hidden;

            .navBar-serviceLinks {
              display: block;
              width: 100%;

              .navBar-sublistLinkContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  .navBar-expandServicesIconContainer {
    display: none !important;
  }

  &.navBar-stickyNavigationShown {
    top: 0;
  }

  #navBar-logoHomeLinkSticky {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $stickyNav-height;
    text-decoration: none;

    @include responsive(mobile) {
      #navBar-companyNameSticky {
        margin-right: 1rem;
        font-size: 0.85rem;
      }
    }

    #navBar-companyLogoSticky {
      height: 1.75rem;
      width: 1.75rem;
      padding: 0.125rem;
    }

    #navBar-companyNameSticky {
      color: $company-name;
      font-family: $heading-font;
      font-size: $text-large;
    }
  }

  .navBar-links {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    bottom: 0;

    @include responsive(stickyNav-linkCutoff-links) {
      min-height: $stickyNav-height !important;

      .navBar-linkContainer {
        min-height: $stickyNav-height;
        padding-left: 0.65rem;
        z-index: 1 !important;

        .navBar-link {
          display: flex;
          align-items: center;
          min-height: $stickyNav-height;
          font-size: $text-linkSmall;

          .navBar-linkText {
            min-height: $link-height;
          }

          &.navBar-link-active {
            .navBar-linkText {
              border-bottom: 2px solid $primary;
            }
          }
        }

        .navBar-servicesSlidedown {
          .navBar-serviceLinksHide {
            display: none;
          }

          .navBar-serviceLinks {
            background-color: $secondary !important;

            .navBar-sublistLinkContainer {
              padding: 0.5rem;
              .navBar-sublistLink-active {
                .navBar-linkText {
                  color: $primary;
                }
              }
              .navBar-linkText {
                color: $secondary-contrast;
                font-size: $text-linkSmall;
              }

              &:not(:first-child) {
                border-top: 1.5px solid $secondary-contrast !important;
              }
            }
          }
        }

        &:not(:hover) {
          .navBar-serviceLinks {
            display: none;
          }
        }

        &:hover {
          .navBar-serviceLinks {
            position: absolute;
          }
        }
      }
    }

    @include responsive(desktop) {
      .navBar-linkContainer {
        padding-left: 0.5rem;
      }
    }

    @include responsive(widescreen) {
      .navBar-linkContainer {
        padding-left: 1rem;

        .navBar-link {
          font-size: $text-link;
        }
      }
    }

    .navBar-linkContainer {
      display: block;

      .navBar-link {
        display: flex;
        align-items: center;
      }
    }
  }
}
