@import "./Styles/master";

html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  text-rendering: optimizeLegibility;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: $background-tertiary;
}

::-webkit-scrollbar-thumb {
  background: #888;
  cursor: pointer;

  &:hover {
    background: $background-secondary;
  }
}
