@import "../../../../Styles/master";

#contact {
  background-color: $page-color;

  #contact-image {
    @include parallax;

    #contact-header {
      @extend %textbox;
    }
  }

  #contact-info {
    padding: 4rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    #contact-infoHeader {
      font-family: $heading-font;
      font-size: $text-section-header;
      padding-bottom: 2rem;
      text-align: center;
      font-weight: bold;
    }

    .contact-infoItem {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0.5rem 0.75rem;
      font-family: $text-font;
      font-size: $text-large;
      text-decoration: none;
      color: inherit;

      .contact-infoIcon {
        font-size: $text-large;
        color: $primary;
        padding-right: 0.25rem;
      }
    }
  }

  #contact-map {
    width: 100vw;
    height: 50vh;
    border: none;
    padding: 0;
    margin-top: 0.5rem;
  }
}
