@import "../../../../Styles/master";

#payment {
  #payment-image {
    @include parallax;

    #payment-header {
      @extend %textbox;
    }
  }

  #payment-text {
    @extend %paragraph-text;
  }

  #payment-options {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 4rem 1rem;
    @extend %background-gradient-dark;

    .payment-option {
      box-sizing: border-box;
      flex-basis: 95%;
      display: flex;
      padding: 1rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      color: $background-text;
      font-family: $text-font;
      font-size: $text-section-header;
      text-align: center;

      @include responsive(widescreen) {
        flex-basis: 50%;
        padding: 4rem;

        .separator {
          display: none;
        }
      }

      .payment-optionImage {
        height: 8rem;
        padding-bottom: 2rem;
      }

      .payment-optionTitle {
        flex-basis: 100%;
        padding-bottom: 1rem;
        font-weight: bold;
      }

      .payment-optionDescription {
        flex-basis: 100%;
        font-size: $text-standard;
      }

      #payment-prepayFormDownload {
        border: 2px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        padding: 0.5rem;
        font-family: $text-font;
        font-size: $text-large;
        text-decoration: none;
        color: $primary;
        transition: 0.25s linear all;
        border-radius: 3px;

        &:hover {
          transform: scale(1.1);
        }
      }

      .separator {
        flex-basis: 100%;
      }
    }
  }
}
