@import "../../../Styles//master";

#servicesPanel {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10rem 0;
  @extend %background-gradient-dark;

  @include responsive(tablet) {
    flex-direction: column;
  }

  #servicesPanel-title {
    flex-basis: 100%;
    margin-bottom: 6rem;
    color: $primary;
    font-family: $heading-font;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
  }

  .servicesPanel-service {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 33%;
    color: $background-text;
    font-family: $text-font;
    font-size: $text-section-header;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      .servicesPanel-icon {
        scale: 1.1;
      }
    }

    @include responsive(tablet) {
      margin: 4rem 0;
    }

    .servicesPanel-icon {
      height: 10rem;
      width: auto;
      margin-bottom: 1.5rem;
      transition: 0.2s ease-in-out all;
    }
  }
}
