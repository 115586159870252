$media: screen;

$mobileSmall: 375px;
$mobile: 450px;
$tabletSmall: 600px;
$tablet: 850px;
$desktop: 800px;
$stickyNav-linkCutoff: 920px;
$widescreen: 1100px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobileSmall {
    @media #{$media} and (max-width: $mobileSmall) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tabletSmall {
    @media #{$media} and (max-width: $tabletSmall) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopThin {
    @media #{$media} and (min-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == stickyNav-linkCutoff-dropdown {
    @media #{$media} and (max-width: $stickyNav-linkCutoff) {
      @content;
    }
  } @else if $breakpoint == stickyNav-linkCutoff-links {
    @media #{$media} and (min-width: $stickyNav-linkCutoff) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $widescreen) {
      @content;
    }
  }
}

@mixin parallax {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;

  @include responsive(widescreen) {
    height: 40rem;
    background-attachment: fixed;
  }
}

@mixin shadow($color, $thickness) {
  text-shadow: (-$thickness) (-$thickness) 0 $color,
    $thickness (-$thickness) 0 $color, -$thickness $thickness 0 $color,
    $thickness $thickness 0 $color !important;
}

// Color Palette
$background: #020b21;
$backgroundTransparent: #061231c0;
$background-text: white;
$background-secondary: #d34927;
$background-secondary-text: #020b21;
$background-tertiary: #1e1917;
$background-tertiary-text: #e0e0e0;
$page-color: white;
$primary: #d34927;
$primary-contrast: black;
$secondary: #36454f;
$secondary-contrast: white;
$company-name: #d34927;

// Gradients
%background-gradient-dark {
  background: #020b21;
  background: linear-gradient(15deg, #020b21, #051336);
}

// Text Sizing
$text-page-header: 2.25rem;
$text-section-header: 2rem;
$text-large: 1.5rem;
$text-link: 1.25rem;
$text-linkSmall: 0.75rem;
$text-standard: 1.25rem;
$text-small: 1rem;
$text-tiny: 0.9rem;

// Typefaces
$text-font: "Metrophobic";
$heading-font: "Lato";

%textbox {
  max-width: 90%;
  margin: auto;
  padding: 1rem;
  border-radius: 4px;
  background-color: $backgroundTransparent;
  box-shadow: 0 4px 8px $backgroundTransparent;
  color: $primary;
  font-family: $heading-font;
  font-size: $text-page-header;
  font-weight: bold;
  text-align: center;
}

%paragraph-text {
  margin: auto;
  padding: 4rem 0rem;
  font-family: $text-font;
  font-size: $text-large;
  text-align: center;

  @include responsive(desktop) {
    width: 60%;
  }

  @include responsive(desktopThin) {
    width: 70%;
  }

  @include responsive(tablet) {
    width: 80%;
  }

  @include responsive(tabletSmall) {
    width: 90%;
  }

  @include responsive(mobile) {
    width: 95% !important;
  }
}
