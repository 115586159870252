@import "../../../../../Styles/master";

#fuelOil {
  background-color: $page-color;

  #fuelOil-image {
    @include parallax;

    #fuelOil-header {
      @extend %textbox;
    }
  }

  #fuelOil-about {
    margin: auto;
    padding: 4rem 0.5rem;
    font-family: $text-font;
    font-size: $text-large;
    text-align: center;

    @include responsive(desktop) {
      width: 60%;
    }

    @include responsive(desktopThin) {
      width: 70%;
    }

    @include responsive(tablet) {
      width: 80%;
    }

    @include responsive(tabletSmall) {
      width: 90%;
    }

    @include responsive(mobile) {
      width: 95% !important;
    }
  }

  #fuelOil-mapOverview {
    margin: auto;
    padding: 4rem 0.5rem;
    font-size: $text-large;
    font-family: $text-font;
    text-align: center;

    @include responsive(desktopThin) {
      width: 70%;
    }

    @include responsive(tablet) {
      width: 80%;
    }

    @include responsive(tabletSmall) {
      width: 90%;
    }

    @include responsive(mobile) {
      width: 95% !important;
    }

    .services-towns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 4rem;

      .services-town {
        padding: 1rem 1rem;
        font-weight: bold;
      }
    }
  }

  #fuelOil-mapContainer {
    overflow: hidden;
    height: 50vh;

    #fuelOil-map {
      width: 100%;
      height: 60vh;
      border: none;
      padding: 0;
      margin-top: 0.5rem;
      top: -65px !important;
      position: relative;
    }
  }
}
