@import "../../../Styles/master";

#separatorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 70%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include responsive(mobile) {
    width: 80%;
  }

  @include responsive(mobileSmall) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 90%;
  }

  #separatorLine {
    margin: auto;
    transition: width 0.5s ease-out;
    width: 0%;
    border-bottom: solid 2px $primary;
  }
  .separatorLineGrown {
    width: 50% !important;
  }
}
